import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({fontSizes, breakpoints, palette, spacing}) => ({
  hat: {
    color: `${palette.primary.main}!important`,
    fontFamily: 'CamptonSemiBold',
    fontSize: '20px',
    marginTop: 0,
    [breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  hatHatMarginTop: {
    marginTop: '2em',
  },
  hasMarginTop: {
    marginTop: spacing(5),
  },
  title: {
    fontFamily: 'CamptonMedium',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: spacing(3),
    fontSize: fontSizes.title,
    [breakpoints.down('sm')]: {
      fontSize: fontSizes.largeDescription,
    },
  },
  hasSmallPaddingBottom: {
    paddingBottom: spacing(2),
  },
  mediumTitle: {
    fontSize: fontSizes.largeDescription,
    [breakpoints.down('sm')]: {
      fontSize: fontSizes.description,
    },
  },
  subTitle: {
    fontSize: fontSizes.subTitle,
    [breakpoints.down('sm')]: {
      fontSize: fontSizes.innerTitle,
    },
  },
  innerTitle: {
    padding: 0,
    margin: 0,
    fontSize: fontSizes.innerTitle,
  },
  smallTitle: {
    padding: 0,
    margin: 0,
    fontSize: fontSizes.description,
  },
  verySmallTitle: {
    padding: 0,
    margin: 0,
    fontSize: 16,
  },
  preWrap: {
    whiteSpace: 'pre-wrap',
  },
  centered: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  fontFamilyMedium: {
    fontFamily: 'CamptonMedium',
  },
  fontFamilyBook: {
    fontFamily: 'CamptonBook',
  },
  colorWhite: {
    color: 'white!important',
  },
  colorGray: {
    color: '#9d9d9d!important',
  },
  colorPrimary: {
    color: `${palette.primary.main}!important`,
  },
}))

export default useStyles
