import * as R from 'ramda'
import {bool, node, number, oneOf, shape, string} from 'prop-types'
import classNames from 'classnames'
import React from 'react'
import Typography from '@mui/material/Typography'
import useStyles from './styles'

const Title = ({
  isCentered,
  children,
  color,
  hat,
  type,
  variant,
  hasHatMarginTop,
  hasMarginTop,
  hasSmallPaddingBottom,
  fontFamily,
  animation,
}) => {
  const classes = useStyles()
  const animationAos = R.pathOr('', ['animation'], animation)
  const animationDelay = R.pathOr('', ['delay'], animation)
  const animationDuration = R.pathOr('', ['duration'], animation)

  return (
    <>
      {hat && (
        <p
          data-aos={animationAos}
          data-aos-delay={animationDelay}
          className={classNames(classes.hat, {
            [classes.centered]: isCentered,
            [classes.hatNoMarginTop]: hasHatMarginTop,
          })}
        >
          {hat}
        </p>
      )}
      {children && (
        <Typography
          data-aos={animationAos}
          data-aos-delay={animationDelay}
          data-aos-duration={animationDuration}
          variant={variant}
          className={classNames(classes.title, classes.preWrap, {
            [classes.centered]: isCentered,
            [classes.title]: type === 'title',
            [classes.mediumTitle]: type === 'mediumTitle',
            [classes.subTitle]: type === 'subTitle',
            [classes.innerTitle]: type === 'innerTitle',
            [classes.smallTitle]: type === 'smallTitle',
            [classes.verySmallTitle]: type === 'verySmallTitle',
            [classes.fontFamilyMedium]: fontFamily === 'medium',
            [classes.fontFamilyBook]: fontFamily === 'book',
            [classes.colorWhite]: color === 'white',
            [classes.colorGray]: color === 'gray',
            [classes.colorPrimary]: color === 'primary',
            [classes.hasMarginTop]: hasMarginTop,
            [classes.hasSmallPaddingBottom]: hasSmallPaddingBottom,
          })}
        >
          {children}
        </Typography>
      )}
    </>
  )
}

Title.propTypes = {
  animation: shape({
    animation: string,
    delay: number,
    duration: number,
    offset: number,
    direction: string,
  }),
  children: node,
  color: string,
  fontFamily: string,
  hasHatMarginTop: bool,
  hasMarginTop: bool,
  hasSmallPaddingBottom: bool,
  hat: string,
  isCentered: bool,
  type: oneOf([
    'innerTitle',
    'largeTitle',
    'mainTitle',
    'title',
    'mediumTitle',
    'subTitle',
  ]),
  variant: oneOf(['h1', 'h2', 'h3']),
}

Title.defaultProps = {
  animation: null,
  children: '',
  color: '',
  fontFamily: '',
  hasHatMarginTop: false,
  hasMarginTop: false,
  hasSmallPaddingBottom: false,
  hat: '',
  isCentered: false,
  type: 'mainTitle',
  variant: 'h1',
}

export default Title
